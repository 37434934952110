<template>
	<div class="guide">
		<van-nav-bar
			title="操作指引"
			left-arrow
			fixed
			:z-index='999'
			class="qjc-nav-bar"
			@click-left="$router.back()"
		/>
		
		<van-image
			class="qjc-relative"
			width="100%"
			:src="require('@/assets/images/toutu.png')"
		/>
		
		<div class="main qjc-bg-fff qjc-texta-l qjc-relative">
			<dl>
				<dt class="qjc-c-dark qjc-fts-32">
					1、註冊/登陸
					<div class="line"></div>
				</dt>
				<dd class="qjc-fts-28">點擊個人中心頁面的註冊/登陸，進入到註冊/登陸界面，使用手機號進行<span class="qjc-c-primary"><!-- <router-link to="/regist">註冊</router-link>/ --><router-link to="/login">登陸</router-link></span>。</dd>
				<dd class="qjc-clearfix">
					<van-image
						class="qjc-fl"
						width="3rem"
						lazy-load
						:src="require('@/assets/images/zhuce1.png')"
					/>
					<van-image
						class="qjc-fr"
						width="3rem"
						lazy-load
						:src="require('@/assets/images/zhuce2.png')"
					/>
				</dd>
			</dl>
			<dl>
				<dt class="qjc-c-dark qjc-fts-32">
					2、購買體檢套餐
					<div class="line"></div>
				</dt>
				<dd class="qjc-fts-28">進入到米因雲健康體檢的首頁，根據您的體檢需求， 選擇適合您的體檢套餐進行<span class="qjc-c-primary"><router-link to="/home/buyCombo">購買</router-link></span>。</dd>
				<dd class="qjc-clearfix">
					<van-image
						class="qjc-fl"
						width="3rem"
						lazy-load
						:src="require('@/assets/images/goumai1.png')"
					/>
					<van-image
						class="qjc-fr"
						width="3rem"
						lazy-load
						:src="require('@/assets/images/goumai2.png')"
					/>
				</dd>
			</dl>
			<dl>
				<dt class="qjc-c-dark qjc-fts-32">
					3、填寫個人信息及問卷資料
					<div class="line"></div>
				</dt>
				<dd class="qjc-fts-28">完成套餐購買後，請您先填寫個人信息與問卷資料，為了進行準確的大數據分析，請務必如實填寫。</dd>
				<dd class="qjc-clearfix">
					<van-image
						class="qjc-fl"
						width="3rem"
						lazy-load
						:src="require('@/assets/images/wenjuan1.png')"
					/>
					<van-image
						class="qjc-fr"
						width="3rem"
						lazy-load
						:src="require('@/assets/images/wenjuan2.png')"
					/>
				</dd>
			</dl>
			<dl>
				<dt class="qjc-c-dark qjc-fts-32">
					4、預約並及時進行線下體檢
					<div class="line"></div>
				</dt>
				<dd class="qjc-fts-28">完成個人信息與問卷資料的填寫後，請根據您的實際情況進行線下體檢預約，並如期進行線下體檢。</dd>
				<dd class="qjc-clearfix">
					<van-image
						class="qjc-fl"
						width="3rem"
						lazy-load
						:src="require('@/assets/images/yuyue1.png')"
					/>
					<van-image
						class="qjc-fr"
						width="3rem"
						lazy-load
						:src="require('@/assets/images/yuyue2.png')"
					/>
				</dd>
			</dl>
			<dl>
				<dt class="qjc-c-dark qjc-fts-32">
					5、查看米因健康大數據體檢報告
					<div class="line"></div>
				</dt>
				<dd class="qjc-fts-28">在完成線下體檢後，請耐心等待，我們會第一時間獲取您的體檢結果，並為您生成您的專屬健康大數據體檢報告。報告生成後，您可以登錄本平台並在歷史記錄中進行<span class="qjc-c-primary"><router-link to="/user/history">查看您的體檢報告</router-link></span>。</dd>
				<dd class="qjc-clearfix">
					<van-image
						class="qjc-fl"
						width="3rem"
						lazy-load
						:src="require('@/assets/images/chakan1.png')"
					/>
					<van-image
						class="qjc-fr"
						width="3rem"
						lazy-load
						:src="require('@/assets/images/chakan2.png')"
					/>
				</dd>
			</dl>
		</div>
	</div>
</template>

<script>
	import Vue from "vue"
	import { NavBar, Image, Lazyload } from "vant"
	Vue.use(NavBar)
	   .use(Image)
	   .use(Lazyload);
	
	export default{
		name: "userGuide",
		data (){
			return {
				start: false//是否開始動畫
			}
		},
		mounted (){
			this.$nextTick(()=>{
				this.start = true;
			})
		}
	}
</script>

<style lang="scss" scoped>
	.guide{
		background-color: #6681FA;
		padding: 0.88rem 0 0.64rem;
		
		&>.van-image{
			z-index: 1;
		}
		.main{
			margin: -0.24rem 0.24rem 0;
			padding: 0.39rem 0.32rem 0.64rem;
			border-radius: 0.24rem;
			z-index: 2;
			
			dl{
				&:not(:last-child){
					margin-bottom: 0.63rem;
				}
				dt{
					position: relative;
					display: inline-block;
					font-weight: 500;
					line-height: 0.32rem;
					margin-bottom: 0.18rem;
					
					.line{
						position: absolute;
						left: 0;
						bottom: 0;
						width: 100%;
						height: 0.12rem;
						background-color: #FF9376;
						border-radius: 0.06rem;
						z-index: -1;
					}
				}
				dd{
					color: #777F8F;
					line-height: 0.42rem;
					
					span{
						a{
							color: #6681FA;
							font-weight: 500;
						}
						a:visited,
						a:active{
							color: #6681FA;
						}
					}
					&.qjc-clearfix{
						margin-top: 0.28rem;
					}
				}
			}
		}
	}
</style>
